.trustedPartner-wrp {
  .trustedPartner {
    .image-preview {
      border-top: 1px solid var(--shade24);
      margin-top: 30px;
      padding-top: 30px;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      padding-bottom: 30px;


      .each-img-box {
        width: 210px;
        height: 125px;
        display: block;
        flex-shrink: 0;
        position: relative;
        background: #fff;
        border-radius: 10px;
        padding: 15px;
        .delete-btn{
          background: #134870;
          right: 5px;
          top: 5px;
          img{
            width: 25px;
            height: 25px;
          }
        }
        .rc {
          display: block;
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          border-radius: 10px;
        }
      }
    }
    .btn-wrp{
      padding-bottom: 75px;
    }
  }
}