.addEditNews{
    .img-upload-wrp{
        .image-upload-label{
            svg{
                width: 100%;
                height: 73px;
            }
        }
    }
    .comment-list-box{
        li{
            padding: 15px;
            border: 1px solid #C6C6C6;
            border-radius: 8px;
            font-size: 0.95rem;
            .edit-btn{
                background: #134870 !important;
                position: relative;
                left: 10px;
                top: -10px;
            }


            &:not(:last-child){
                margin-bottom: 15px;
            }
        }
    }
}