.custom-navbar2 {
  height: 100%;
  left: 0;
  max-height: 100vh;
  overflow: hidden;
  border-right: 1px solid var(--greyBG);
  box-shadow: none;
  z-index: 999;
  position: relative;
  box-shadow: 0px 0px 10px 1px var(--darkShade25);

  .logo-sec {
    width: 100%;

    .logo-wrp {
      padding: 9px 13px;
      display: flex;
      justify-content: center;
      // border-bottom: 1px solid var(--greyBG);
      // background: var(--Primary);
      // border-radius: 0 0 20px 20px;
      border-bottom: 1px solid var(--greyBG);
      border-radius: 0 0 0px 0px;
      box-shadow: 0px 0px 3px 0px var(--darkShade25);

      span {
        display: block;
        max-width: 150px;
        line-height: 0;

        img {
          width: 100%;
          object-fit: contain;
          object-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 45px;
        }

        a {
          display: flex;

          img {
            opacity: 1;
            transition: 0.5s all;
            transition: max-width 0.2s all;
            max-width: 150px;

            &.show {
              opacity: 1;
              transition: 0.5s all;
            }

            &.hide {
              opacity: 0;
              transition: 0.5s all;
              max-width: 0px;
            }

            @media only screen and (max-width: 600px) {

              &.hide,
              &.show {
                opacity: 1 !important;
                max-width: 150px !important;
              }

              &:nth-child(2) {
                display: none !important;
              }
            }


          }

          &+.MuiButtonBase-root.MuiIconButton-root {
            position: absolute;
            right: 0px;
            top: 0;
            padding: 0;
            height: 100%;
            min-width: 10px;
            width: 15px;
            border-radius: 0;
            color: var(--shade24);

            @media only screen and (max-width: 600px) {
              width: 40px;
            }
          }
        }
      }
    }
  }

  .custom-header {
    padding-left: 0px;
    padding-right: 0px;
  }

  .menu-wrp {
    display: flex;
    height: 100%;
    padding-top: 20px;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    justify-content: space-between;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--adminBG);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--LogoGrey);
      border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--LogoGrey);
    }

    a {
      display: block;
    }


    button {
      width: 100%;
      padding: 8px 23px;
      border-radius: 0;
      margin-bottom: 1px;
      gap: 15px;
      align-items: center;
      justify-content: space-between;
      border-left: 5px solid transparent;

      .drop-link-text {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 15px;
      }

      .menu-icon {
        line-height: 0;
        width: 20px;
        height: 20px;
        display: block;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .MuiListItemText-root {
        margin: 0;
        text-align: left;
        color: var(--Black00);

        span,
        .MuiTypography-root {
          font-size: 14px;
          font-weight: 400;
          font-family: var(--Roboto);
        }
      }
    }

    &.active {
      button {
        background-color: var(--greyBG);
        border-left: 5px solid var(--navPlaceHolder);

        .menu-icon {
          svg {
            width: 100%;
            height: 100%;
          }
        }

      }
    }

    .dropDown-box {
      nav.MuiList-root {
        padding: 0;

        .MuiCollapse-root.MuiCollapse-vertical {
          .MuiCollapse-wrapper {
            .MuiCollapse-wrapperInner {
              .MuiList-root {
                a {
                  .nav-link-btn {
                    padding-left: 40px;

                    .menu-icon {
                      width: 15px;
                      height: 15px;
                    }
                  }

                }

              }
            }
          }
        }

      }
    }

    a:hover {
      button {
        background: var(--greyBG);
      }

      span {
        color: var(--Black00);
      }
    }

    &.menu-wrp-closed {
      button {
        padding: 5px 5px;
        flex-direction: column;
        gap: 0px;

        .MuiListItemText-root {
          .MuiTypography-root {
            font-size: 13px;
          }
        }
      }
    }

  }

}





.custom-navbar {
  position: fixed;
  left: 0;
  top: 0;
  // width: 200px;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  border-right: 1px solid var(--greyBG);
  z-index: 999;
  box-shadow: 0px 0px 10px 1px var(--darkShade25);
  transition: 0.5s all;
  &.custom-drawer{
    background: var(--White) !important;
    padding-top: 0;
  }
  @media only screen and (max-width: 991px) {
    & {
      background-color: var(--White);
    }
  }
  @media only screen and (max-width: 660px) {
    & {
      border-right: none;
      box-shadow: none;
    }
  }

  .navbar-wrp {
    height: 100%;
    width: var(--DrawerWidth);
    display: flex;
    flex-direction: column;
    transition: 0.5s all;

    .logo-wrp {
      padding: 6.5px 13px;
      // background-color: var(--Secondary);
      display: flex;
      justify-content: center;
      border-bottom: 1px solid var(--greyBG);
      border-radius: 0 0 0px 0px;
      box-shadow: 0px 0px 3px 0px var(--darkShade25);
      transition: 0.5s all;

      a {
        display: flex;
        height: 45px;
        width: 100%;
        position: relative;
        span {
          display: block;
          max-width: 150px;
          line-height: 0;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          &.BlueLogo {
            left: 15px;
            right: unset;
              
          }
          img {
            width: 100%;
            object-fit: contain;
            object-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 45px;
            display: none;
            transition: 0.5s all;
          }
          &.hide {
            img{
              display: none;
              transition: 0.5s all;
            }            
          }
          &.show {
            img{
              display: block;
              transition: 0.5s all;
            }            
          }

        }
        &+.chevron-btn {
          position: absolute;
          right: 0px;
          top: 0;
          padding: 0;
          height: 100%;
          min-width: 20px;
          width: 15px;
          border-radius: 0;
          color: var(--shade24);
          button{
            background-color: transparent;
            border: none;
            width: 20px;
            height: 100%;
            cursor: pointer;
            svg{
              width: 10px;
              height: 15px;
              path{
                stroke: var(--shade24);
              }
            }
          }
  
          @media only screen and (max-width: 600px) {
            width: 40px;
          }
        }
      }

      
    }

    .menu-wrp {
      display: flex;
      height: 100%;
      padding-top: 20px;
      flex-direction: column;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      justify-content: space-between;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: var(--adminBG);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--LogoGrey);
        border-radius: 0px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--LogoGrey);
      }

      a {
        display: block;
        &.active-nav,
        &:hover {
          button {
            background-color: var(--shade25);
            border-left: 5px solid var(--Secondary);
    
            .menu-icon {
              svg {
                width: 100%;
                height: 100%;
              }
            }
    
          }
        }
      }


      button {
        width: 100%;
        padding: 8px 23px;
        border-radius: 0;
        margin-bottom: 1px;
        gap: 15px;
        align-items: center;
        justify-content: space-between;
        border-left: 5px solid transparent;
        transition: 0.5s all;
        background: var(--White);
        .menu-icon {
          line-height: 0;
          width: 25px;
          height: 25px;
          display: block;

          svg {
            width: 100%;
            height: 100%;            
          }
        }

        .MuiListItemText-root {
          margin: 0;
          text-align: left;
          color: var(--Black00);

          span,
          .MuiTypography-root {
            font-size: 15px;
            font-weight: 600;
            font-family: var(--Roboto);
          }
        }

        .drawer-close & {
          padding: 5px 5px;
          flex-direction: column;
          gap: 0px;
          transition: 0.5s all;

          .MuiListItemText-root {
            .MuiTypography-root {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}


.mob-drawer-btn {
  position: absolute;
  left: 0px;
  top: 0;
  z-index: 10;
  padding: 0;
  height: 55px;
  display: none;
  .MuiButtonBase-root.MuiButton-root{
    min-width: 50px;
  }
}

.closeBackBtn {
  position: fixed;
  left: 0;
  top: 0;
  padding: 0;
  min-width: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: var(--darkShade5);
  min-height: 100vh;
  display: none;
  z-index: 12;

  &:hover {
    background: var(--darkShade5);
  }

  .drawer-close & {
    width: 0;
  }

  @media only screen and (max-width: 660px) {
    display: block;
  }
}

@media only screen and (max-width: 660px) {
  .mob-drawer-btn {
    display: flex;
  }
}