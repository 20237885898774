.main-content-wrapper {

  .addEditUser {
    .date-picker-box {
      .MuiStack-root {
        padding: 0;

        .date-picker {
          width: 100%;
        }
      }
    }
  }

  .user-main-wrp {
    .user-card-wrp {
      margin-top: 25px;
      border: 1px solid var(--greyBG);
      border-radius: 8px;
      padding: 15px 0;
      display: flex;
      overflow: hidden;
      overflow-x: auto;
      display: none;

      &::-webkit-scrollbar {
        height: 0px;
      }

      &::-webkit-scrollbar-track {
        background: var(--adminBG);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--LogoGrey);
        border-radius: 0px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--LogoGrey);
      }

      .each-user-card {
        padding: 0 20px;
        min-width: 225px;
        border-radius: 0px;

        &:not(:last-child) {
          border-right: 1px solid var(--greyBG);
        }

        span {
          font-size: 12px;
          color: var(--Black00);

          img {
            width: 25px;
            height: 25px;
            display: inline-block;
            float: right;
            object-fit: contain;
            object-position: center;
          }
        }

        h4 {
          margin: 0;
          padding-top: 3px;
          padding-bottom: 3px;
          font-size: 15px;
          font-weight: 700;
          color: var(--Black00);
        }

        p {
          font-size: 13px;
          color: var(--Black00);
          margin: 0;

          img {
            width: 35px;
            height: 20px;
            display: inline-block;
            float: right;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }

    .main-content-box {
      .tab-sec-wrp {
        .tab-header {
          button:nth-child(1) {
            svg {
              width: 20px;
            }
          }

          button:nth-child(2) {
            svg {
              // width: 15px;
            }
          }

          button:nth-child(3) {
            svg {
              width: 15px;
              height: 29px;
            }
          }
        }

        .panel-tabel-sec {

          [aria-label="Status"],
          [aria-label="Action"],
          [aria-label="Actions"] {
            .MuiDataGrid-columnHeaderTitleContainer {
              justify-content: center;
            }
          }

          [data-field="status"],
          [data-field="idVal"] {
            justify-content: center;
          }

          [data-field="actions"] {
            .MuiButtonBase-root {
              svg {
                width: 20px;
                height: 20px;

                path {
                  fill: var(--Secondary)
                }

                circle {
                  fill: var(--Secondary)
                }
              }
            }
          }
        }
      }
    }

  }

  &.user-permitos-wrp {
    .user-permission-head {
      padding-top: 25px;

      h1 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .panel-tab-sec {
      .tab-sec-wrp {
        .tab-header {
          .MuiTabs-flexContainer {
            gap: 10px;
          }

          button.tab-items {
            min-height: 35px;
            min-width: 85px;

            b {
              color: var(--Black00);
            }

            svg {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }

    .content-table-box {
      padding-bottom: 25px;

      .content-table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;
        border: 1px solid var(--greyBG);
        border-radius: 8px 8px 0px 0px;
        border-bottom: none;

        h1 {
          font-size: 16px;
          font-family: var(--Roboto);
          font-weight: 500;
          margin: 0;
        }

        button {
          padding: 0;
          margin: 0;
          border: none;
          background: transparent;
          line-height: 0;

          svg {
            width: 20px;
            height: 5px;
          }
        }
      }

      .content-table-body {
        ul {
          border: 1px solid var(--greyBG);
          border-radius: 0 0 8px 8px;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 20px;

            &:not(:last-child) {
              border-bottom: 1px solid var(--greyBG);
            }

            h1 {
              font-size: 14px;
              margin: 0;
            }

            span.MuiSwitch-root {
              width: 46px;
              height: 25px;
              padding: 0px;

              .MuiButtonBase-root.MuiSwitch-switchBase {
                padding: 3px;

                &.Mui-checked {
                  color: var(--navPlaceHolder);
                }
              }

              .MuiSwitch-track {
                border-radius: 25px;
                background-color: var(--greyBG);
              }
            }
          }
        }
      }






    }
  }

  &.user-details-wrp {
    .user-details-grid {
      padding-top: 25px;

      &>.main-container-wrp {
        padding-left: 10px;
        padding-right: 10px;
      }

      .navigation-btn-wrp {
        padding-left: 15px;
        padding-right: 15px;
      }

      .main-content-box {
        &>.row {
          @media only screen and (max-width: 768px) {
            & {
              flex-direction: column-reverse;
            }
          }
        }
      }


      .each-grid-box {
        background-color: var(--adminBG);
        border: 1px solid var(--greyBG);
        border-radius: 8px;
        padding: 15px;
        box-shadow: 0px 1px 2px 0px var(--shade004);
        margin-bottom: 25px;

        .sub-head {
          margin: 0;
          color: var(--Black00);
          font-weight: 400;
          font-size: 15px;
          padding-bottom: 20px;
        }
      }

      .userId-Card {
        display: flex;
        align-items: center;
        gap: 15px;

        span {
          display: block;
          width: 70px;
          height: 70px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .id-con {
          overflow: hidden;

          * {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          h4 {
            margin: 0;
            color: inherit;
            font-weight: 600;
            font-size: 15px;
          }

          p {
            margin: 0;
            color: inherit;
            font-size: 14px;
          }

          .btn-wrp {
            padding-top: 10px;

            .sqr-btn {
              padding: 7px 25px;
            }
          }

        }


      }

      .documents-type {

        .document-box {
          .each-document-box {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding-bottom: 20px;

            span {
              display: block;
              width: 30px;
              height: 30px;
              max-width: 30px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
                object-fit: cover;
                object-position: center;
              }
            }

            .document-con {
              flex: 1;

              p {
                font-size: 13px;
                margin: 0;
              }

              .placeholder {
                width: 90%;
                display: block;
                max-width: 100%;
                height: 10px;
                background: var(--greyBG);
                margin: 3px 0;
                border-radius: 10px;
              }
            }

            .btn-wrp {
              max-width: 82px;
            }


          }

          .btn-wrp {
            .sqr-btn {
              width: 100%;
              justify-content: center;
            }
          }
        }
      }

      .information-box {
        .information-form-box {
          &>.row {
            width: calc(100% + 30px);
            left: -15px;
            position: relative;

            .col-lg-6 {
              @media only screen and (min-width: 992px) {
                &:nth-child(odd) {
                  padding-left: 7.5px;
                }

                &:nth-child(even) {
                  padding-right: 7.5px;
                }
              }
            }
          }

          // .each-TextField {
          //   padding-bottom: 15px;

          //   h4 {
          //     font-size: 14px;
          //     margin: 0;
          //     font-weight: 600;
          //   }

          //   .MuiFormControl-root {
          //     input {
          //       padding: 8px 14px;
          //       background-color: var(--greyBGE6);
          //       border-radius: 8px;
          //       font-size: 14px;
          //       font-weight: 400;
          //       font-family: var(--Roboto);
          //     }

          //     ::after,
          //     ::before {
          //       display: none;
          //     }
          //   }

          //   .date-picker-box {
          //     &>.MuiStack-root {
          //       overflow: hidden;

          //       * {
          //         &:focus-visible {
          //           outline: -webkit-focus-ring-color auto 0px !important;
          //         }
          //       }

          //       .date-picker {
          //         width: 100%;
          //         min-width: auto;
          //         background-color: var(--greyBGE6);
          //         border-radius: 8px;

          //         .MuiInputBase-root.MuiOutlinedInput-root {
          //           .MuiOutlinedInput-notchedOutline {
          //             border: none !important;
          //           }
          //         }
          //       }
          //     }
          //   }

          //   .custom-radioBox {
          //     .MuiButtonBase-root {
          //       .MuiSvgIcon-root {
          //         color: var(--Secondary);
          //       }

          //       .MuiTypography-root {
          //         font-size: 14px;
          //       }

          //       &.Mui-disabled:not(.Mui-checked) {
          //         .MuiSvgIcon-root {
          //           color: var(--placeholder);
          //         }
          //       }
          //     }
          //   }
          // }





          .btn-wrp {
            width: 100%;

            .sqr-btn {
              width: 100%;
              justify-content: center;
            }
          }
        }
      }
    }

    .profile-img-con {
      display: block;
      line-height: 0;
      max-width: 250px;

      .img-upload-wrp {
        .upload-img {
          label {
            width: 75px;
            height: 75px;
            border: 1px solid var(--Secondary);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;

            svg {
              width: 25px;
              height: 25px;
            }

            input {
              opacity: 0;
              width: 0;
              height: 0;
            }
          }
        }

        .each-img-blk {
          width: 100%;
          height: 100%;
          border: none;
          position: relative;

          button {
            min-height: 0;
            height: auto;
            line-height: 0;
            background: #fff;
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 100%;
            border: 1px solid var(--placeholder);
            padding: 1px;

            svg {
              width: 20px;
              height: 20px;
            }
          }

          .profile-img-sec {
            display: block;
            width: 75px;
            height: 75px;

            span {
              width: 75px;
              height: 75px;
            }
          }
        }
      }

      span {
        border-radius: 100%;
        border: 1px solid var(--Secondary);
        overflow: hidden;
        line-height: 0;
        width: 75px;
        height: 75px;

        &.sm-loader {
          position: absolute;
          width: 75px !important;
          height: 75px !important;
          display: flex !important;
          justify-content: center;
          align-items: center;
          background: #fff;

          svg {
            width: 40px;
            height: 40px;

            path {
              fill: var(--Secondary);
            }
          }
        }

        img {
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  &.user-documentation-wrp {
    .user-doc-head {
      padding-top: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .lft-sec-box {
        color: var(--Black00);

        h4 {
          margin: 0;
          color: inherit;
          font-weight: 600;
          font-size: 15px;
        }

        p {
          margin: 0;
          color: inherit;
          font-size: 13px;
        }
      }

      .ryt-sec-box {}

      h1 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .panel-tab-sec {
      .tab-sec-wrp {
        .tab-header {
          .MuiTabs-flexContainer {
            gap: 10px;
          }

          button.tab-items {
            min-height: 35px;
            min-width: 85px;

            b {
              color: var(--Black00);
            }

            svg {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }

    .content-table-box {
      padding-bottom: 25px;

      .content-table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;
        border: 1px solid var(--greyBG);
        border-radius: 8px 8px 0px 0px;
        border-bottom: none;

        h1 {
          font-size: 16px;
          font-family: var(--Roboto);
          font-weight: 500;
          margin: 0;
        }

        button {
          padding: 0;
          margin: 0;
          border: none;
          background: transparent;
          line-height: 0;

          svg {
            width: 20px;
            height: 5px;
          }
        }
      }

      .content-table-body {
        ul {
          border: 1px solid var(--greyBG);
          border-radius: 0 0 8px 8px;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 20px;

            &:not(:last-child) {
              border-bottom: 1px solid var(--greyBG);
            }

            h1 {
              font-size: 14px;
              margin: 0;
            }

            span.MuiSwitch-root {
              width: 46px;
              height: 25px;
              padding: 0px;

              .MuiButtonBase-root.MuiSwitch-switchBase {
                padding: 3px;

                &.Mui-checked {
                  color: var(--navPlaceHolder);
                }
              }

              .MuiSwitch-track {
                border-radius: 25px;
                background-color: var(--greyBG);
              }
            }
          }
        }
      }
    }
  }
}

.custom-radioBox {
  .MuiButtonBase-root {
    .MuiSvgIcon-root {
      color: #134870;
    }

    .MuiTypography-root {
      font-size: 14px;
    }

    &.Mui-disabled:not(.Mui-checked) {
      .MuiSvgIcon-root {
        color: #134870;
      }
    }
  }
}


.UserListLayout {
  .user-create-btn-wrp {
    position: relative;
    z-index: 9;
    margin-bottom: -30px;

    @media only screen and (max-width: 1201px) {
      & {
        margin-bottom: 45px;
      }
    }
  }

  .tab-body {
    .panel-tabel-sec {
      .PC-Table {
        .MuiDataGrid-row {
          max-height: 75px !important;
          min-height: 75px !important;
          .MuiDataGrid-cell {
            max-height: 65px !important;
            min-height: 65px !important;
          }
        }

      }
    }
  }
}